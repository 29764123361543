<script>

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkDialog from "@components/general/AkDialog";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCircuitService from "@services/bilanCircuitService";

export default {
	components: {AkFormList, AkDialog, AkDropdown},
	mixins: [randomRef, roleMixin],
	metaInfo() {
		return {
			title: "bilan_circuit.list",
		}
	},
	data() {
		return {
      year: 0,
      yearList: [],
      bilanCircuitList: [],
			list: [],
      currentYear: null,
			current: {},
			loading: true,
		}
	},
	mounted() {
		this.loading = true;
		this.getRef().showTotalLoader();
    
    let p1 = bilanCircuitService.findAllCurrent();
    p1.then(data => this.bilanCircuitList = data);
    
    let p2 = bilanCircuitService.findCurrentYear();
    p2.then(data => this.currentYear = data);
    
    let p3 = bilanCircuitService.findAllYears();
    p3.then(data => {
      this.yearList.push({value: 0, label: this.$t('current_year')});
      data.forEach(year => this.yearList.push({value: year, label: year + ' - ' + (year + 1)}))
    });
    
    Promise.all([p1, p2, p3]).then(()=>{
      this.initData();
      this.loading = false;
      this.getRef().hideLoader();
    });
	},
	methods: {
    initData() {
      this.list = [];
      let tempList = [];
      let ceiCount = {};
      this.bilanCircuitList.forEach(bilanCircuitItem => {
        if (ceiCount[bilanCircuitItem.ceiId] === undefined) {
          ceiCount[bilanCircuitItem.ceiId] = 0;
        }
        ceiCount[bilanCircuitItem.ceiId]++;
        
        if (tempList.every(item => {
          return item.ceiId !== bilanCircuitItem.ceiId
        })) {
          tempList.push(bilanCircuitItem);
        }
      });
  
      tempList.forEach(item => {
        item.circuitCount = ceiCount[item.ceiId];
        this.list.push(item);
      });
    },
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1;
      if (year !== 0) {
        p1 = bilanCircuitService.findAllByYear(year);
        p1.then(data => {
          this.bilanCircuitList = data;
        });
      } else {
        p1 = bilanCircuitService.findAllCurrent();
        p1.then(data => {
          this.bilanCircuitList = data;
        });
      }
      
      Promise.all([p1]).then(()=>{
        this.loading = false;
        this.getRef().hideLoader();
        this.initData();
      });
    },
		delete() {
			this.getRef().resetMessages();
      let toDelete = this.bilanCircuitList.filter(b => b.ceiId === this.current.ceiId);
      let promises = [];
      for (const element of toDelete) {
        let p = bilanCircuitService.delete(element);
        promises.push(p);
        p.catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
          this.validationSubmitted = false;
        });
      }
      Promise.all(promises).then(data=>{
        this.list = this.list.filter(val => val.ceiId !== data[0].ceiId);
        this.getRef().success(this.$t("bilan_circuit.deleted"));
      });
		},
		openDeleteDialog(data) {
			this.current = data;
			this.$refs.dialogDelete.show();
		},
		displayYear(data) {
			return data.year + '-' + (data.year+1);
		},
		displayWeek(data) {
			let start = data.weekStart;
			let end = data.weekEnd;
			return start===end ? start : start + '-' + end;
		},
	},
  watch: {
    year(newType) {
      this.reload(newType);
    },
  },
}
</script>

<template v-if=!roleLoading>
	<AkFormList
		:ref="ref"
		:title="$t('bilan_circuit.list')"
		:displayGoBack=true>
		<template v-slot:action>
      <router-link :to="{ name: 'bilanCircuitSynthese' }" class="btn btn-inverse-primary mr-2">
        {{ $t('goto_table') }}
      </router-link>
			<router-link v-if=this.canCreateCircuit() :to="{ name: 'bilanCircuitCreate' }" class="btn btn-inverse-primary">
				<i class="fe fe fe-plus pr-1"/>
				{{ $t('add') }}
			</router-link>
		</template>
		<template v-slot:list>
      <div class="row">
        <AkDropdown :label="$t('year')"
                    v-if=this.canViewCircuitArchived()
                    v-model="year"
                    :options=this.yearList
                    class-name="col-md-2"/>
      </div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card card-statistics">
						<div class="card-body">
							<div class="table-responsive">
                <DataTable :always-show-paginator="false" :paginator="true" :rows="10" :loading="loading"
                           :rowsPerPageOptions="[10,20,50]" :value="list" class="table"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           removableSort responsiveLayout="scroll" stripedRows>
									<template #empty>
										{{ $t("list_empty") }}
									</template>
                  <Column :header="$t('year')">
                    <template #body="slotProps">
                      <template v-if=!slotProps.data.year>{{ currentYear }} - {{ currentYear + 1 }}</template>
                      <template v-else>{{ slotProps.data.year }} - {{ slotProps.data.year + 1 }}</template>
                    </template>
                  </Column>
									<Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
										<template #body="slotProps">
											{{ slotProps.data.agerLabel }}
										</template>
									</Column>
									<Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
										<template #body="slotProps">
											{{ slotProps.data.uerLabel }}
										</template>
									</Column>
									<Column field="ceiLabel" :header="$t('cei_label')" :sortable="true">
										<template #body="slotProps">
											{{ slotProps.data.ceiLabel }}
										</template>
									</Column>
                  <Column field="circuitCount" :header="$t('bilan_circuit.nb_circuits')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.circuitCount }}
                    </template>
                  </Column>
                  <Column field="author" :header="$t('bilan.author')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.author }}
                    </template>
                  </Column>
                  <Column bodyStyle="text-align: right; overflow: visible">
										<template #body="slotProps">
                      <router-link :to="{name: 'bilanCircuitDetails', params: {id: slotProps.data.ceiId}}"
                                   class="btn btn-xs btn-inverse-primary">
                        <i class="fe fe-eye"/>
                      </router-link>
											<router-link v-if="!slotProps.data.year && this.canEditCircuit() && this.canEditOrDeleteReview(slotProps.data)"
                                   :to="{name: 'bilanCircuitUpdate', params: {id: slotProps.data.ceiId}}"
                                   class="btn btn-xs btn-inverse-primary ml-1">
												<i class="fe fe-edit"/>
											</router-link>
											<span v-if="this.canDeleteCircuit() && this.canEditOrDeleteReview(slotProps.data)"
                            @click="openDeleteDialog(slotProps.data)"
                            class="btn btn-xs btn-inverse-danger ml-1">
												<i class="fe fe-trash"/>
											</span>
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:extra>
			<AkDialog :auto-hide-on-validate="true" :cancel-label="$t('no')" :title="$t('bilan.delete_dialog')"
                :validate-label="$t('yes')" @validate="this.delete()" ref="dialogDelete" width="450px">
				<div class="confirmation-content">
					<i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
					<span>{{ $t('bilan.confirm_delete') }}</span>
				</div>
			</AkDialog>
		</template>
	</AkFormList>
</template>